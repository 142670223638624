@import "../../colors";
@import "../../mediaqueries";

.userprofileWrapper {

    &__Small{
        @include media( "screen", ">largeWidth" ){
            display: none;
        };            
        @include media( "screen", "<largeWidth" ){
            display: initial;
        };
    }

    &__Large{
        @include media( "screen", ">largeWidth" ){
            display: initial;
        };            
        @include media( "screen", "<largeWidth" ){
            display: none;
        };
    }  
}

.userprofile {
    margin-left: 0.5rem;
    font-family: 'Roboto';
    font-weight: 300;
}

.userButton {
    background: transparent;
    margin-left: 2rem;
    margin-right: 0.5rem;
    padding: 0;
    border: none;
    flex-grow: 1;
    text-align: right;
    max-width: 41px;


    &:after{
        content: none;
    }    
    &:hover {
        background-color: transparent;
    }
    &:focus {
      outline:0 !important;
      box-shadow: none;
    }  
  }
