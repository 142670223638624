@import "../../../mediaqueries";
@import "../../../colors";

$borderColorUI: #DCDCDC;
$buttonColor: #2f2f2f;
$border: 1px solid $COLOR_CONTAINER_BORDER;
$borderSubdomain: 0.6rem solid #f7f7f7;
$borderSite: 0.6rem solid #fdebeb;

$borderRadius: 0.6rem;

.tabsetting {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background-color: #fff;
  padding: 1.4rem;
  padding-left: 0;
  padding-right: 0;
  border-radius: 0.2rem;


  border-left: $border;
  border-right: $border;
  border-bottom: $border;

  &:last-child {

    border: 1px solid $COLOR_CONTAINER_BORDER;
  }
}

.tabsetting__site {
  border-left: $borderSite;
  margin-left: 0.6rem;
  padding-left: 0.6rem;
}

.tabsetting__site ~ .tabsetting__site {
  border-radius: 0;
}

.tabsetting__child ~ .tabsetting__site {
  border-bottom: $border;
}

.tabsetting__global {
  border: 1px solid $COLOR_CONTAINER_BORDER;
  margin-left: 0rem;
}

.tabsetting__child {

  border-left: $borderSubdomain;
  margin-left: 1.2rem;
  padding-left: 1.2rem;
}

.tabsetting__consistent {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  padding: 1rem;

  a {
      color: $COLOR_ALLER_RED;
    }


  button {
    justify-self: flex-end;
    margin-left: auto;
  }

  h2 {
    font-weight: 600;
    font-size: 1.6rem;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    color: $COLOR_BLACK_TEXT;


    img {
      max-width: 140px;
      margin: 0 2rem 0rem 0;
      display: block;

    }
  }
}
