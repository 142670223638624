$borderColorUI: #DCDCDC;
$buttonColor: #2f2f2f;


$COLOR_PINK_MORDOR_DARK: #7B0006;
$COLOR_PINK_DARK: #A2151C;
$COLOR_PINK_MEDIUM: #DD5158;


.adstxt_wrapper {
  background-color: #fff;
  border-radius: 1.2rem;
  padding: 2rem;
  height: calc(100vh - 200px);

  h2 {
    font-weight: 500;
    font-size: 1.3rem;
    color: #000;
  }

  form {
    height: calc(100% - 40px);

    textarea {
      flex-basis: 100%;



      &:focus {

        outline: 0;
        border: 1px solid #ced4da;
        box-shadow: none;
      }
    }
  }

  :global(.form-group) {
    height: inherit;
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
  }

  button {
    width: 100px;
    max-width: 150px;
    margin-top: 1rem;




    &:global(.btn:disabled) {
      opacity: 0.1;
    }
  }


  :global(.alert) {
    margin-bottom: 0;

  }


}

.form_button_wrapper {
  width: 100%;
  text-align: right;
}