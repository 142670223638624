.button_wrapper {
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  margin-right: 2rem;
  margin-top: 4rem;

  button {
    margin-right: 1rem;
  }
  button:last-child {
    margin-left: auto !important;
    margin-right: 0;
  }
}