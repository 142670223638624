.collapse_head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #dee2e6;
  padding-bottom: 0.8rem;
  padding-top: 0.8rem;
}

.collapse_body {
  padding: 0 1rem;
  background-color: #f2f2f2
}