@import "../../mediaqueries";
@import "../../colors";

$borderColorUI: #DCDCDC;
$buttonColor: #2f2f2f;
$alertColor: #cb2a32;
$globalOptColor: #ffa3a3;
$globalOptDisabledColor: #4ac49b;
$rowHeight: 50px; // calc(1.5em + .75rem + 2px);

// Added a set fixed height to the outer element (form-control)
// Made sure the height was inherited all the way in
// Remove most of the padding found //

.adslot {
  width: 100%;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #d9d9d9;
  min-height: $rowHeight;
  //background-color:$COLOR_ROW;
  padding: 1rem 0;
  overflow: hidden;
  max-height: $rowHeight;
  align-items: center;


  &:nth-child(2n) {
    //background-color:#fff;
  }

  @include media( "screen", "<smallWidth" ){
    display: block;
  };


  &:last-child {
    margin-bottom: 2rem;
  }

  :global(.form-control) {
    height: $rowHeight;
    background: none;
  }

  input[type=text] {
    border: none;
    box-shadow: none;
  }

  ///// Typeahead menu-overrides
  :global(.dropdown-menu) {
    min-height: $rowHeight;
    border-top: none;
    top: -2px !important;
  }
}

.adslot_sizes--open {
  transition: max-height 0s linear;
  max-height: 300px;
  overflow: visible;

  .adslot_sizes {

    input[type=text] {
      //height: auto !important;
    }

    :global(.rbt) {
      //height: auto;
    }

    :global(.rbt-menu.dropdown-menu.show) {
      //top: 16px !important;
    }
  }
}

//////////////////////////////////////////
// SLOT OPTIONS (CELL)
//////////////////////////////////////////

.slot_option {
  text-align: left;
  font-family: 'Roboto' !important;
  font-size: 1.2rem;
  font-weight: 400;
  position: relative;
  flex-grow: 0;
  flex-shrink: 0;
  &:nth-child(1) {
    flex-basis: 20%;
  }

  &:nth-child(2) {
    flex-basis: 70%;
  }

  &:nth-child(3) {
    flex-basis: 10%;
  }



  :global(.custom-control-label) {
    font-size: 1rem;
  }



  :global(.btn) {
    font-size: 0.9rem;
    padding-top: 0.275rem;
    padding-bottom: 0.275rem;
  }

  :global(.form-control) {
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
  }

  :global(.dropdown-item) {
    padding-left: 0.75rem;
  }

  :global(.dropdown-item) {
    padding-left: 0.4rem;
  }

  :global(.form-control:focus) {
    border-radius: 0;
    border-bottom: 1px solid gray;
  }

  :global(.rbt input[type=text]) {
    padding-bottom: 0.4rem;
  }

  input[type=text], label, .rbt-token {
    font-family: 'Roboto';
    font-weight: 300;
  }
  :global(.rbt-token-disabled) {
    padding: 0.75rem;
    background-color: $globalOptDisabledColor !important;
  }
}

.slot_option_global {
  background-color: $globalOptColor !important;
}

.slot_option_global_delete {
  background-color: $alertColor !important;
}

.slot_option__center {
  input {
    text-align: center;
  }
}

.adslot_format {
  &.is_global {
    $w : 8px;
    $h : 8px;

    padding-left: 0.8rem;

    &, &:before, &:after {
      display: block;
      border-radius: 50%;
      width: $w;
      height: $h;
    }

    &:before, &:after {
      position: absolute;
      content: '';
    }

    &:before {
      transform: translate(-16px, 25px - 4px);
      background-color: $globalOptColor;
    }
  }





}




//////////////////////////////////////////
// SIZES
//////////////////////////////////////////
$sizeButtonHeight: $rowHeight - 12px;
.adslot_sizes {

  :global(.form-control) {
    height: auto;
    padding: 0;
  }

  :global(.rbt-input-multi.focus) {
    border: none;
    color: #495057;
    outline: 0;
    box-shadow: none;
    border-bottom: 1px solid gray;
  }

  :global(.rbt-input-multi) {
    border-radius: 0;
    border: none;

    input[type=text] {
      border: none;
      padding: 0 0 0 0.375rem;
      height: $rowHeight;
      margin: 0;

    }
  }

  :global(.rbt-token-removeable) {
    background-color: $COLOR_SECONDARY;
    margin-right: 1rem;
    margin-bottom: 0.375rem;
    margin-top: 0.3rem;
    padding-bottom: 0.475rem;
    color: #f3f3f3;
    letter-spacing: 0.2px;
    padding-left: 0.75rem;
    min-width: 110px;
    max-width: 110px;
    text-align: center;
    height: $sizeButtonHeight;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: $rowHeight * 0.5 - $sizeButtonHeight * 0.5;

    font-family: 'Roboto';
    font-weight: 300;



    span {
      color: #fff;
    }
  }


  :global(.rbt-input-multi .rbt-input-wrapper) {
    margin-bottom: 0;
    margin-top: 0;
    padding-left: 1px; // So that a size on the row underneath for sure will be hidden by the drop down menu
  }

  :global(.rbt-input-wrapper) {
    //height: $rowHeight;
    //padding-top: $rowHeight * .5 - $sizeButtonHeight * .5;
  }

  :global(.rbt-menu.dropdown-menu.show) {
    border-radius: 0;
  }
}



//////////////////////////////////////////
// outstream
//////////////////////////////////////////

.adslot_outstream {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: $rowHeight;

}



.adslot_lazyload {
  position: relative;
  flex-basis: 8%;

  :global(.alert_danger) {
    left: 90%;
    top: $rowHeight * 0.5 - 38 * 0.5; // 38 is from the alert component and not really available here
    display: none;
  }

  :global(.mb-2) {
    margin-bottom: 0 !important;
  }





  form {
    display: inline-block;
    margin-bottom: 0.5rem;
    margin-left: 0.75rem;

    input {
      height: initial;

    }
  }


  .form-control {
    height: initial !important;
    color: red;
  }

  input[type=text] {

    padding-top: 0.275rem;
    padding-bottom: 0.275rem;
  }

  input[type=text]:invalid {
    border: initial;
  }


  :global(div.alert) {
  }

  :global(.form-control) {
    padding-top: 0;
  }

  :global(.btn-group) {
    padding-top: 0.375rem;
  }


  :global(.btn-primary) {
    background-color: #5d5d5d;

    border: none;
  }

  :global(.btn-primary.focus) {
    box-shadow: none;
    border: none;
  }

  :global(.btn-primary:hover) {
    background-color: $buttonColor;
    border: none;
  }


  :global(.btn-group .btn-primary:not(:disabled):not(.disabled).active) {
    background-color: $buttonColor;
    border: none;
  }

}

///// toolbar //////

.adslot_toolbar {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  flex-basis: 8%;
  margin: 0;
  padding: 0.2rem 0 0 0;
}

///// VALIDATION //////

:global(input.form-control:invalid) {
  border-color: red;
}

.adslot_lazyload--invalid {
  input[type=text]:invalid {
    border: 1px solid $alertColor;
  }

  input[type=text] {
    border: 1px solid $alertColor;
  }

  :global(.alert_danger) {
    display: flex;
    position: absolute;

    @include media( "screen", "<smallWidth" ){
      display: block;
      left: initial;
      top: initial;
      position: initial;
    };

  }
}

