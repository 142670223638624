@import "../../colors";
@import "../../mediaqueries";


.addSite{

    &Form :not(:first-child){
        margin-left: 1rem;
    }        
    
    &Button{

        &__Small{

            @include media( "screen", "<smallWidth" ){
                margin-top: 0.5rem;
            };            
        }        
    }
}


input[type=text]{
    
    border: none;
    background-color: $COLOR_ROW;


    font-family: 'Roboto';
    font-weight: 300;


    &:focus {
        background-color: $COLOR_ROW;
        box-shadow: none;
        border: none;
    }


    &:invalid{
        border-color: inherit;
        &:focus{
            border-color: red;
        }
    }
}