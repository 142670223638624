.input_wrapper {

  padding: 0;

  .col {
    margin-left: 0 !important;
  }
  input[type=text] {
    padding: 0 0 0 1rem;
  }

  input[disabled] {
    padding: 0;
  }
  input[type="text"]:disabled {
    background: none;
    text-align: center;
    text-transform: uppercase;
  }

  label {
    margin-bottom: 0;
    font-family: sans-serif, 'Roboto';
    font-weight: 100;
    font-size: 1rem;
    letter-spacing: 0.2px;
  }
}

.input_label__leading {
  padding-bottom: 1rem;
}

