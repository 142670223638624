
h2.sitename__header {
  font-weight: 200;
}

.sitename__header_bg {
  background-color: #86bca8;
  padding: 0.5rem;
  border-radius: 10px;
}

