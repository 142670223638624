$COLOR_ROW: #f7f7f7;
$COLOR_CONTAINER_BORDER: #DCDCDC;
$COLOR_PRIMARY_ACTION: #495a6b;
$COLOR_SECONDARY: #69ac98;
$COLOR_PRIMARY: #62646c;
$COLOR_BLACK_TEXT: #202124;


$COLOR_ERROR: #c91d12;
$COLOR_ERROR_TYPE: #ffe1e2;

$COLOR_BORDER: #DCDCDC90;

$COLOR_ALLER_RED: #CB2A32;