@import "../../mediaqueries";
@import "../../colors";

.tab_title_bar {

  justify-content: flex-end;
  padding-right: 1rem;
  padding-left: 0.8rem;
  margin: 0 4px;

  h4 {
    margin: 0;
    padding: 0;
    justify-self: flex-start;
    margin-right: auto;
    align-self: center;
  }

  :global(.nav-item) {
    a {
      color: $COLOR_ALLER_RED;
    }
  }
}