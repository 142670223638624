@import "../../mediaqueries";

$alertColor: #cb2a32;
$bgColorVeryLight: #f8d7da;
$allerRed: #cb2a32;


:global(.alert_left) {
  width: 0;
  height: 0;
  border-top: 19px solid transparent;
  border-bottom: 19px solid transparent;
  border-right:19px solid $bgColorVeryLight;
  border-radius: 0.25rem;

  @include media( "screen", "<smallWidth" ){
    display: none;
  }
}

:global(.alert_danger) {

  width: auto;

  @include media( "screen", "<smallWidth" ){
    width: auto;
  }
  z-index: 999;
  font-size: 0.9rem;
  display: flex;
  height: 38px;
  transition: opacity .15s linear;
}

.alert_danger__wrapper {
  background-color: $bgColorVeryLight;
  border-color: $allerRed;
  border-radius: 0.25rem;
  width: inherit;

  padding-right: 0.4rem;
  padding-left: 0.4rem;


  display: flex;
  height: inherit;
  justify-content: center;
  align-items: center;
  margin-left: -2px;
  color: #721c24;

  span {
    color: #cb2a32;
    letter-spacing: 0.2px;
    white-space: nowrap;
  }
}

.alert_danger__exclamation {
    height: 48px;
    background-color: $bgColorVeryLight;
    border-radius: 0.25rem;
    border: 1px solid $allerRed;
    padding-right: 0.4rem;
    padding-left: 0.4rem;
  
  .alert_danger__wrapper {
    background: none;
  }

  svg {
    overflow: visible;

    circle {
      fill: $allerRed;
    }
  }
}