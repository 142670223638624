@import "../../../colors";

.modular_settings_wrapper {

  padding: 1.4rem;
  p {
    margin: 2rem 0;
    font-size: 1rem;
    font-weight: 300;
  }
}

.settings_headline_leading {
  &:first-child {
    input {
      margin-top: 1rem;
    }
  }
}

.setting_row div label, h5.setting_header {
  font-weight: 600;    
}

.setting_invalid {
  input[data-valid=false] {
    border: 1px solid $COLOR_ALLER_RED !important;
  }
}

.setting_row {
  margin-top: 1.4rem;
  margin-bottom: 1.4rem;
  padding: 0;
  margin-left: 0;
  margin-right: 0;

}