@import "../../mediaqueries";
@import "../../colors";

$borderColorUI: #DCDCDC;
$buttonColor: #2f2f2f;
$border: 1px solid $COLOR_CONTAINER_BORDER;


.sitesetting--header {
  display: flex;
  flex-direction: row;


  border-bottom: 1px solid #d9d9d9;
  width: 100%;
  padding: 1rem 0;

  @include media( "screen", "<smallWidth" ){
    display: none;
  }

  h4 {
    text-align: left;
    margin: 0;
    padding: 0;
    flex-grow: 0;
    flex-shrink: 0;

    &:nth-child(1) {
      flex-basis: 20%
    }

    &:nth-child(2) {
      flex-basis: 80%
    }


    &.sitesetting--header__center {
      text-align: center;
    }
  }
}

.double-grow {
  flex-grow: 1;
}

.sitesetting__wrapper {

  margin: 0;
  padding: 0 1rem;
}


.sitesetting__form {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin: 0 0.2rem;
}

//------------------------------------------------------
// Site setting footer
//------------------------------------------------------

.sitesetting__footer {
  width: 100%;
  padding: 2rem 1rem 0 1rem;
  margin-top: 1rem;
  text-align: right;
  display: flex;
  flex-direction: column;

  align-items: flex-end;

  button {
    margin-left: 1rem;
  }
}

