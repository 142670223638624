
$wrapperWidth : 20px;
$wrapperHeight: 20px;
$w : 8px;
$h : 8px;


.global_marker {
  width: 100%;
  height: 50px;
  border-radius: 50%;



  &:before, &:after {
      display: block;
      border-radius: 50%;
      width: $w;
      height: $h;
    }

    &:before, &:after {
      position: absolute;
      content: '';
    }

    &:before {
      transform: translate( -12px, 20px);
      background-color: #ffa3a3
    }
  }



/*
width: 20px;
height: 20px;
border-radius: 50%;
                 background-color: red;
position: absolute;
padding-left: 0.8rem;
top: 14px;
left: -16px;*/