@import "../../../colors";


.collapsebutton {
  background: transparent;
  margin: 0;
  padding: 0;
  border: none;
  flex-grow: 1;
  text-align: right;
  max-width: 41px;
  transform: scale(1.1);


  &:disabled {

    opacity: 0.01;

    circle {
      fill: #716f6f;
      stroke: transparent;
    }

    polyline {
      stroke: #fff;
    }
  }

  svg {
    transition: transform 200ms;
    circle {
      stroke: $COLOR_CONTAINER_BORDER;
      fill: none;
    }
    g {
      g {
        stroke: $COLOR_PRIMARY;
      }
    }
  }

  &:active {
    svg {
      transition: transform 200ms;
      circle {
        stroke: #d4d4d4;
        fill: transparent;
      }

      g {
        g {
          stroke: #2f2f2f;
        }
      }
    }

  }

  &:focus {
    outline:0 !important;
  }
}

.collapsebutton_default {

}

.collapsebutton_dark {
  svg {

    circle {
      stroke: #d4d4d4;
      fill: #2f2f2f !important;
    }

    g {
      g {
        stroke: #ffffff !important;
      }
    }
  }
}

.collapsebutton_closed {
  svg {
    transform: rotate(0deg);
  }

}

.collapsebutton_open {
  svg {
    transform: rotate(180deg);
  }
}

.collapsebutton_regular {
  margin: 0;
}




.collapsebutton_small {
  transform: scale(0.9);
  svg {

    circle {
      stroke: #d4d4d4;
      fill: transparent;
    }

    g {
      g {
        stroke: #2f2f2f;
      }
    }
  }

  &:active {
    svg {
      circle {
        stroke: transparent;
        fill: #2f2f2f;
      }

      g {
        g {
          stroke: #fff;
        }
      }
    }

  }

}