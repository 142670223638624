@import "../../colors";



.changelog {
  font-size: 0.8rem;

  cursor: pointer;
  min-height: 24px;
  text-align: left;
  letter-spacing: 0.2px;
  padding: 0.2rem;
  overflow: hidden;

  display: block;
  text-align: right;
  margin-top: 1.4rem;



}

.changelog_wrapper {
  transition: transform 100ms;
  transition-timing-function: ease;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 40px;
  align-items: center;



}

.changelog_title {

  margin-bottom: 4px;
  letter-spacing: 0.2px;
  user-select: none;
}

.changelog_open{

  .chevron {
    transform: rotate(0deg);
  }

}

svg {
  margin-bottom: 2px;
}



.changelog_row {
  display: block;
  min-height: 1.4rem;
  width: 100%;
}

:global(.badge) {
  padding: 0.25rem;
  margin-left: 0.4rem;

}

.chevron {
  margin-right: 0.4rem;
  transform: rotate(180deg);
}