.delete-button {
  border: none;
  background-color: transparent;


  &:focus {
    outline:0 !important;
  }

  svg {

    border-radius: 50%;
    border: 1px solid #d4d4d4;

    circle {
      fill: transparent;
      stroke: #d4d4d4;
    }

    path {
      fill: #2f2f2f;
    }
  }
}