@import "../../colors";
@import "../../mediaqueries";

$ROW_HEIGHT: 70px;

.metadata_wrapper {
  h4 {
    border-bottom: 1px solid $COLOR_BORDER;
    padding: 0 1rem 0.4rem 1rem;
  }
}

.metadata_delete {
  width: 100%;
  text-align: right;
}

.metadata_invalid {
  input {
    border: 1px solid red !important;
  }
}


.meta_input_small {
  input[type=text] {
    width: 3rem;
  }
}


.meta_input_small {
  flex-basis: 20% !important;
}

.meta_input_medium {
  input[type=text] {
    flex-basis: 70% !important;
  }
}

/**
  Settings with an input field of type text
 */
.metadata_input_group {
  display: flex;
  align-items: center;
  flex-direction: row;
  border-radius: 0.2rem;
  border-radius: 0.2rem;
  justify-content: flex-start;
  margin: 0;
  height: 70px;
  flex-shrink: 0;


  label {
    margin: 0;
    font-family: sans-serif, 'Roboto';
    font-weight: 400;
    white-space: nowrap;
    font-size: 1rem;
    letter-spacing: 0.2px;
    flex-basis: 26%;
    flex-grow: 0;
    flex-shrink: 0;
  }

  input[type=text] {

    flex-basis: 20%;

    margin: 0;
    background-color: #ffffff;
    color: $COLOR_BLACK_TEXT;
    font-family: sans-serif, 'Roboto';
    text-align: left;
    font-size: 1rem;
    border-radius: 0;
    font-weight: 500;
    margin-right: 10px;

    &:invalid {
      border-color: red;
    }

    &:focus {
      border-bottom: 1px solid $COLOR_BORDER;
    }
  }
  
}
.custom-placeholder {
  ::placeholder {
    color: #ffa3a3;
  }
}

.metadata_props {

  display: flex;
  width: 100%;
  flex-direction: column;
  margin: 1rem 0;
  padding: 0 1rem;

  @include media( "screen", "<smallWidth" ){
    flex-direction: column;
  };

}
.metadata {
  display: flex;
  align-items: center;

  height: 70px;
  margin-bottom: 0;
  justify-content: space-between;
  min-width: 240px;


  :global(.custom-switch .custom-control-label::after) {
    top: calc(.3rem + 2px);
  }

  :global(.custom-control-label::before) {
    top: 0.3rem;
  }

  label {
    margin-bottom: 0;
    font-family: sans-serif, 'Roboto';
    font-weight: 500;
    font-size: 1rem;
    letter-spacing: 0.2px;

  }

  &> label {
    text-transform: capitalize;
    margin-right: 0.5rem;
    font-weight: 100;
  }
}

.props_container {
  display: flex;

  flex-direction: column;
  flex-basis: 20%;
  margin-right: 4rem;

  &.large {
    flex-basis: 50%;
  }

  &.auto {
    flex-basis: auto;
    flex-grow: 1;
    flex-shrink: 0;
  }

  @include media( "screen", "<smallWidth" ){
    margin-right: 0;
  };

  &:last-child {
    margin-right: 0;
  }

  p {
    padding-top: 1rem;
    font-weight: 300;
    font-size: 1rem;
    font-family: 'Roboto';
    margin-bottom: 0;
  }
}


h6 {
  padding-top: 1rem;
  font-size: 1.1rem;
  font-weight: 400;
  margin-top: 1.4rem;
  margin-bottom: 1rem;
}