@import "../../colors";
@import "../../mediaqueries";

.navbar {
  margin: 1rem 0 4rem 0;
  img {
    height: 40px;
  }
}

:global(.navbar-nav .nav-link.active) {
  color: $COLOR_ALLER_RED !important;
  font-weight: 600;
  border-bottom: 1px solid;
}

:global(.navbar-nav .nav-link) {
  COLOR: $COLOR_BLACK_TEXT !important;
  margin-left: 1rem;
  margin-right: 1rem;
  padding-left: 0;
  padding-right: 0;
}

:global(.navbar-brand) {
  padding-right: 1rem;
}