.layout {
  background-color: #fff;
  padding: 1.4rem;
  border: 1px solid #DCDCDC;
  border-radius: 1.2rem;

  h2 {
    font-weight: 500;
    font-size: 1.3rem;
    color: #000;
    border-bottom: 1px solid #00000020;
    padding-bottom: 0.4rem;
    margin-bottom: 2rem;

  }
}