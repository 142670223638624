@import "../../colors";
@import "../../mediaqueries";

.timeout_wrapper {
  margin-right: 1rem;
  display: flex;
  justify-content: flex-end;
  & p{
    margin: 0;
    font-size: 0.8rem;
  }
}


