@import "../../../mediaqueries";
@import "../../../colors";

.cache_container {
  display: flex;
  flex-direction: column;
  padding: 1.2rem;
  label {
    width: 80px;
    margin: 0;
    padding: 0;
    font-family: 'Roboto' !important;
    font-weight: 100;
    letter-spacing: 0.2px;
  }
  p {
    margin-top: 1rem;  
  }
}

.cache_button_wrapper {
  width: 100%;
  text-align: right;
}
