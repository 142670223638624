@import "../../mediaqueries";

.login_wrapper {

  height: 100vh;


  display: flex;
  align-items: center;
  justify-content: center;

  form {
    max-width: 50%;


    @include media( "screen", "<smallWidth" ){
      max-width: 90%;
    };


    margin-bottom: 3rem;
    box-shadow: 0 1px 3px rgba(0,0,0,.13);
    padding: 1.2rem;
    background-color: #fff;
    letter-spacing: 0.2px;




  }

  input {
    background-color: #fbfbfb;
    letter-spacing: 0.2px;
  }

  label {
    font-weight: 400;
    font-size: 0.8rem;
    margin-bottom: 0;
  }
}

.submit_wrapper {
  margin-top: 1rem;
  text-align: right;

  button {
    min-width: 80px;

  }




}

:global(.btn-aller) {
  background-color: #ca2a32;
  border-color: #dc3545;
  color: #fff;
  //
}

:global(.btn-aller:hover) {
  color: #fff;
}

:global(.btn-aller:focus) {
  box-shadow: 0 0 0 0.2rem rgba(225,83,97,.5);
}