.option {
  margin-bottom: 4rem;

  input[type=text] {
    background: #f7f7f7 !important;
    padding: .375rem .75rem !important;
    border-radius: .25rem;
  }
}

.option_wrapper {
  padding: 1rem;
}
