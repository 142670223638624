@import "../../mediaqueries";
@import "../../colors";

$rowHeight: 50px; // calc(1.5em + .75rem + 2px);
$sizeButtonHeight: $rowHeight - 12px;
.admin_verticals {

  display: flex;
  flex-direction: column;
  align-items: flex-end;
flex-grow: 1;

  :global(.form-control) {
    height: auto;
    padding: 0;

  }

  :global(.rbt) {
    width: 100%;
    margin-bottom: 1rem;
  }

  :global(.rbt-input-multi) {
    border-radius: 0;
    border: none;
    width: 100%;
    padding: 0.2rem 0.4rem;

    background-color: #f7f7f7;

    input[type=text] {
      border: none;
      padding: 0 0 0 0.375rem;
      height: $rowHeight;
      margin: 0;

    }
  }

  :global(.rbt-input-multi.focus) {
    border: none;
    color: #495057;
    outline: 0;
    box-shadow: none;
  }




  :global(.rbt-input-multi .rbt-input-wrapper) {
    margin-bottom: 0;
    margin-top: 0;
    padding-left: 1px; // So that a size on the row underneath for sure will be hidden by the drop down menu
  }


  :global(.rbt-menu.dropdown-menu.show) {
    border-radius: 0;
    margin: 0;
  }


  :global(.rbt-token-removeable) {
    background-color: $COLOR_SECONDARY;
    margin-right: 1rem;
    margin-bottom: 0.375rem;
    margin-top: 0.3rem;
    color: #f3f3f3;
    letter-spacing: 0.2px;

    padding-left: 1rem;
    padding-top: $rowHeight * 0.5 - $sizeButtonHeight * 0.5;
    padding-bottom: 0.475rem;
    padding-right: 2rem;




    text-align: center;
    height: $sizeButtonHeight;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Roboto';
    font-weight: 300;



    span {
      color: #fff;
    }
  }
}

