@import "colors";
@import "typography";
@import "mediaqueries";

html {
  scroll-behavior: smooth;
}

@mixin error-type {
  font-family: 'Roboto';
  font-size: 0.9rem;
  letter-spacing: 0.2px;
  color: $COLOR_ERROR_TYPE;
}

.App {
  min-height: 100vh;
  background-color: #FFFFFF;

  /// React bootstrap toggle override ///

  .form-control:focus {
    background-color: transparent;
  }

  .custom-control-input:checked~.custom-control-label::before {
    border: none;
    background-color: #2f2f2f;
    box-shadow: none;
  }

  .custom-control-input:focus~.custom-control-label::before {
    box-shadow: none !important;
    border-color: initial;
  }
}

.app-wrapper {
  max-width: 1200px;
  background-color: #FFFFFF;
  margin: 0 auto;
  padding: 2rem;
  position: relative;


  @include media( "screen", "<smallWidth" ){
    padding: 0;
  };



  h4, h5 {
    font-family: 'Open sans', sans-serif;
    letter-spacing: .2px;
    font-style: normal;
    font-feature-settings: normal;
    font-variant-ligatures: normal;
    font-variant-caps: normal;
    font-variant-numeric: normal;
    font-variant-east-asian: normal;
    color: $COLOR_BLACK_TEXT;
    font-stretch: normal;
    line-height: 20px;
  }

 h4 {
   font-size: 1.1rem;
   font-weight: 400;
 }

  h5 {
    font-size: 1.2rem;
    font-weight: 200;
    text-transform: uppercase;
  }



  .btn-primary {
    min-width: 100px;
    max-width: 150px;
    transition: opacity 100ms;
    background-color: $COLOR_PRIMARY;
    border: none;
    font-family: 'Roboto';
    font-weight: 300;
    letter-spacing: 0.2px;
    min-height: 38px;
    letter-spacing: 0.2px;
    text-transform: uppercase;
    font-size: 0.8rem;



    &:disabled {
      opacity: 0.1;
      background-color: $COLOR_SECONDARY;
    }


    &:active, &:hover, &:focus {
      background-color: $COLOR_PRIMARY_ACTION !important;
      box-shadow: none;
    }
  }



  .alert-danger {
    background-color: $COLOR_ERROR;
    border: none;

    @include error-type;

    .alert-link {
      @include error-type;
    }
  }


}



:global(.arrow) {
  display: none;
}



.App-logo {
  height: 40vmin;
  pointer-events: none;
}


h1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 1rem;
  font-size: 1.6rem !important;
}

h1 img {
  max-width: 200px;


}

* {

}

body, html {
  font-size: 16px !important;
}

.tooltip-error {
  .tooltip-inner {

    background-color: $COLOR_ERROR;
  }

  .tooltip-inner {
    @include error-type;

  }

   .arrow::before {
    border-bottom-color: #E30715 !important;
  }


}







