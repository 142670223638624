@import "../../colors";

.aller_toast {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;

  min-width: 100vw;

  :global(.toast) {
    min-width: inherit;
  }

  :global(.toast-body) {
    color: #f3f3f3;
    font-family: 'Roboto';
    font-size: 1.1rem;
    font-weight: 300;
    text-align: center;
    letter-spacing: 0.2px;
  }

  :global(.toast-header) {
    color: #61646b;
    font-family: 'Roboto';
    letter-spacing: 0.2px;
    font-weight: 300;
    text-transform: uppercase;
    font-size: 1rem;
    margin-right: auto!important;
  }
}
.aller_toast_error {
  :global(.toast) {
    background-color: $COLOR_ERROR;
  }
  
}
.aller_toast_success {
  :global(.toast) {
    background-color: $COLOR_SECONDARY;
  }

  
}