@import "../../mediaqueries";
@import "../../colors";



.verticals_container {
  display: flex;
  flex-direction: column;
  padding: 1.2rem;
  label {
    width: 80px;
    margin: 0;
    padding: 0;
    font-family: 'Roboto' !important;
    font-weight: 100;
    letter-spacing: 0.2px;
  }
  p {
    margin-top: 1rem;  
  }
}
.verticals_newSlugButton {
  min-width: 100px;
  max-width: 150px;
  margin-right: 1rem;
  &:hover {
    background-color: #f7f7f7 !important;
    color: #6c757d;
  }
}
.vertical_slug__row {
  margin: 1rem 0 1rem;
  font-family: 'Roboto' !important;
  font-weight: 100;
  letter-spacing: 0.2px;  
}
.verticals__footer {
  text-align: right;
  p {
    display: inline-block;
    margin-right: 1rem;
    margin-top: 0;
    margin-bottom: 0;
    color: $COLOR_ERROR;
  }
}
.settings_slug {
  margin-bottom: 1.2rem;
  display: flex;
  flex-direction: row;
  align-items: baseline;

  p {
    margin-right: 0.5rem;    
  }

  :global(.form-control) {
    width: initial;
  }

  input[type=text] {
    margin: 0;
    flex-grow: 1;
  }

  input[type=text]:focus {
    background-color: #f7f7f7;
  }


}

:global(.rbt-input-wrapper:focus) {
  background-color: blue;

}